import styled, { css } from 'styled-components';

import { A, Caption, H1, Text1 } from 'src/components/Text';
import {
  ErrorNotificationWrapper,
  ErrorNotificationContent,
} from 'src/components/ErrorNotification/error-notification-styled';
import {
  smlMedia,
  smMedia,
  mdMedia,
  retinaMedia,
  themeColor,
  themeImages,
} from 'src/theme/utils';

export const StepMealsPlanWrapper = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  ${smlMedia(css`
    flex-direction: row;
  `)}
`;

export const StepMealsPlanApps = styled.div`
  background: url(/images/mealsplan/bg.png) no-repeat;
  background-position: center;
  background-size: cover;
  ${retinaMedia(css`
    background: url(/images/mealsplan/bg@2x.png) no-repeat;
    background-position: center;
    background-size: cover;
  `)};
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 50vh;
  max-height: 314px;
  &::after {
    content: '';
    display: block;
  }
  ${smMedia(css`
    max-height: unset;
  `)};
  ${smlMedia(css`
    height: 100vh;
    max-height: unset;
    flex: 0 0 50%;
  `)};
  ${mdMedia(css`
    flex: 0 0 50%;
  `)};
`;

export const GoalBtn = styled.button`
  cursor: pointer;
  border: none;
  outline: none;
  flex: 1 1 50%;
  padding: 14px;
  border-radius: 9px;
  background-color: ${themeColor('primary50')};
  &[disabled] {
    cursor: not-allowed;
  }
  ${smMedia(css`
    margin-top: 0;
    flex: 0 1 calc(50% - 8px);
  `)}
  p {
    color: ${themeColor('black0')};
  }
`;

export const GoalBtns = styled.div`
  display: flex;
  flex-direction: column;
  ${GoalBtn}:not(:first-child) {
    margin-top: 1rem;
  }
  ${smMedia(css`
    flex-direction: row;
    justify-content: space-between;
    ${GoalBtn}:not(:first-child) {
      margin-top: 0;
    }
  `)}

  ${mdMedia(
    css`
      ${GoalBtn}:not(:first-child) {
        margin-left: 1rem;
        margin-top: 0;
      }
    `
  )}
`;

export const StepMealsPlanContent = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  ${smlMedia(css`
    margin: auto 0;
    display: flex;
    justify-content: center;
  `)}
  ${mdMedia(css`
    max-width: 70%;
    margin: auto;
  `)}
`;

export const StepMealsPlanChoices = styled.div`
  background: ${themeColor('black0')};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 24px 24px 40px;
  position: relative;
  ${H1}, ${Text1}, ${Caption} {
    color: ${themeColor('black80')};
  }
  ${H1} {
    font-size: 28px;
    line-height: 36px;
    margin-top: 18px;
  }
  ${Text1} {
    margin-top: 26px;
    margin-bottom: 20px;
  }
  ${Caption} {
    font-size: 12px;
    line-height: 18px;
  }
  ${A} {
    color: ${themeColor('primary50')};
    white-space: nowrap;
  }
  ${smMedia(css`
    padding: 92px 24px 40px;
  `)}

  ${mdMedia(css`
    flex: 0 0 50%;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    ${H1} {
      font-size: 44px;
      line-height: 52px;
    }
    ${Text1} {
      margin-top: 48px;
    }
  `)}
`;

export const CheckBoxesWrapper = styled.div`
  ${smMedia(css`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: 188px;
  `)}
  ${mdMedia(css`
    max-width: 70%;
    margin: 0 auto;
  `)}
  ${ErrorNotificationWrapper} {
    margin: 12px 0 0;
    ${ErrorNotificationContent} {
      font-size: 12px;
      line-height: 18px;
    }
  }
`;

export const PrivacyWrapper = styled.div`
  display: flex;
  margin: 40px auto 0;
  ${smMedia(css`
    margin: 40px 0 0;
  `)}
`;

export const PrivacyCheckbox = styled.div`
  width: 24px;
  height: 24px;
  flex: 0 0 24px;
  margin-right: 0.5rem;
  background: url(/icons/cmn_check_default.svg) no-repeat center;
  background-size: contain;
  cursor: pointer;
  &.checked {
    background: url(${themeImages('iconCheck')}) no-repeat center;
  }
`;

export const EmailConfirmWrapper = styled.div`
  display: flex;
  margin: 8px auto 0;
  ${smMedia(css`
    margin: 8px 0 0;
  `)}
`;

export const EmailConfirmCheckbox = styled.div`
  width: 24px;
  height: 24px;
  flex: 0 0 24px;
  margin-right: 0.5rem;
  background: url(/icons/cmn_check_default.svg) no-repeat center;
  background-size: contain;
  cursor: pointer;
  &.checked {
    background: url(${themeImages('iconCheck')}) no-repeat center;
  }
`;
